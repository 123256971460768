<template>
  <div
    v-if="loading"
    class="text-center d-flex justify-content-center align-items-center loader"
  >
    <b-spinner variant="primary" label="Text Centered"></b-spinner>
  </div>
  <div v-else>
    <b-row
      class="d-flex flex-row justify-content-center bg-white rounded-lg bg-light px-1"
    >
      <b-col>
        <b-form>
          <b-row class="rounded-lg bg-white shadow-lg">
            <b-col>
              <p class="text-center py-2 head-line">
                {{ job.title }}
              </p>
              <b-row class="w-100">
                <b-col cols="12" lg="8">
                  <h4 class="px-2 sub-title">JOB DESCRIPTION</h4>
                  <p class="px-2 description" v-html="job.description"></p>
                </b-col>
                <b-col
                  cols="12"
                  class="d-flex justify-content-center align-items-start w-100"
                  lg="4"
                >
                  <div class="border p-1 w-100">
                    <div class="d-flex flex-row justify-content-start">
                      <b-row class="w-100">
                        <b-col cols="4" class="p-0 pl-1">
                          <p class="inner-title">Company</p>
                        </b-col>
                        <b-col cols="1" class="p-0">
                          <p>:</p>
                        </b-col>
                        <b-col cols="7">
                          <p class="short-description">
                            {{ job.user ? job.user.name : "" }}
                          </p>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="d-flex flex-row justify-content-start">
                      <b-row class="w-100">
                        <b-col cols="4" class="p-0 pl-1">
                          <p class="inner-title">Job Category</p>
                        </b-col>
                        <b-col cols="1" class="p-0">
                          <p>:</p>
                        </b-col>
                        <b-col cols="7">
                          <p class="short-description">{{ job.category && job.category.name ? job.category.name : '' }}</p>
                        </b-col>
                      </b-row>
                    </div>
                    <!-- <div class="d-flex flex-row justify-content-start">
                      <b-row class="w-100">
                        <b-col cols="4" class="p-0 pl-1">
                          <p class="inner-title">Salary</p>
                        </b-col>
                        <b-col cols="1" class="p-0">
                          <p>:</p>
                        </b-col>
                        <b-col cols="7">
                          <p class="short-description">{{ job.salary }}</p>
                        </b-col>
                      </b-row>
                    </div> -->
                    <div class="d-flex flex-row justify-content-start">
                      <b-row class="w-100">
                        <b-col cols="4" class="p-0 pl-1">
                          <p class="inner-title">Working Hours</p>
                        </b-col>
                        <b-col cols="1" class="p-0">
                          <p>:</p>
                        </b-col>
                        <b-col cols="7">
                          <p class="short-description">{{ job.hours }}</p>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="d-flex flex-row justify-content-start">
                      <b-row class="w-100">
                        <b-col cols="4" class="p-0 pl-1">
                          <p class="inner-title">Job Location</p>
                        </b-col>
                        <b-col cols="1" class="p-0">
                          <p>:</p>
                        </b-col>
                        <b-col cols="7">
                          <p class="short-description">{{ job.location ? job.location.name : '' }}</p>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="d-flex flex-row justify-content-start">
                      <b-row class="w-100">
                        <b-col cols="4" class="p-0 pl-1">
                          <p class="inner-title">Application Deadline</p>
                        </b-col>
                        <b-col cols="1" class="p-0">
                          <p>:</p>
                        </b-col>
                        <b-col cols="7">
                          <p class="short-description">{{ formateDate(job.last_date) }}</p>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <h4 class="px-2 sub-title">Requirements</h4>
                  <div class="description ml-2" v-html="job.requirements"></div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <h4 class="px-2 sub-title">Skills Required</h4>
                  <div class="description ml-2">
                    <b-row>
                      <b-col cols="12">
                        <b-table
                          :items="job.skill"
                          :fields="fields"
                          class="table w-50"
                          striped
                          hover
                        >
                        <template #cell(skill_id)="row">
                          {{ getSkillsName(row.item.skill_id) }}
                        </template>
                        </b-table>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <h4 class="px-2 sub-title">Duites & Responsibilities</h4>
                  <div
                    class="description ml-2"
                    v-html="job.responsibility"
                  ></div>
                </b-col>
              </b-row>
              <b-row v-if="$can('view benefits', 'Job Post')" class="pb-5">
                <b-col cols="12">
                  <h4 class="px-2 sub-title">Benefits</h4>
                  <div class="description ml-2" v-html="job.benefits"></div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr />
        </b-form>

        <!-- job apply component -->
        <apply v-if="job.employee_apply === 0" :skill="job.skill" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
BButton,
BCard,
BCol,
BForm,
BFormCheckbox,
BFormCheckboxGroup,
BFormDatepicker,
BFormFile,
BFormGroup,
BFormInput,
BFormSelect,
BFormTextarea,
BRow,
BSpinner,
BTable,
} from "bootstrap-vue";
import moment from "moment";
import Apply from "./apply.vue";
import { getSkills } from "@/api/employee";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
    BCard,
    BFormTextarea,
    BFormFile,
    BTable,
    BFormTextarea,
    Apply,
    BSpinner,
  },
  data() {
    return {
      loading: false,
      job: {},
      skills: [],
      form: {
        firstName: "",
        lastName: "",
        vacancy: "",
        field: "",
        email: "",
        Skill: "",
        skillLevel: "",
        coverLetter: "",
      },
      templeteName: "",
      fields: [
        {
          key: "skill_id",
          label: "Skill",
        },
        {
          key: "skill_level",
          label: "Skill Level",
        },
      ],
    };
  },
  created() {
    const id = this.$route.params.id;
    this.getJob(id);
    this.getSkills();
  },
  methods: {
    formateDate(date) {
      // format date to dd-mm-YYYY
      return moment(date).format("DD-MM-YYYY");
    },
    getJob(id) {
      let query = {
        id: id,
      };
      this.loading = true;
      this.$store.dispatch("job/getJob", query).then((res) => {
        this.job = res.data;
      });
      this.loading = false;
    },
    getSkills() {
      let query = {
        limit: 1000,
      };
      this.$store.dispatch("skill/getSkills", query).then((response) => {
        this.skills = response.data;
      });
    },
    getSkillsName(id) {
      if (this.skills.length > 0) {
        return this.skills.find((skill) => skill.id === id).skill;
      }
    },
  },
};
</script>

<style scoped>
.templete-btn {
  /* Gradient */

  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
}

.update-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
}

[dir] .table th,
[dir] .table td {
  border-top: 0 !important;
}

.head-line {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 85px;
  letter-spacing: 0.01em;
  color: #515b60;
}

.description {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
  color: #515b60;
}

.paragraph {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
  color: rgba(21, 28, 37, 0.8);
}

.employee-info-title {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 85px;
  background: -webkit-linear-gradient(#01185e, #264296, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.short-description {
  /* 18 bold */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */

  text-align: justify;

  /* Gradient */

  background: -webkit-linear-gradient(#01185e, #264296, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sub-title {
  /* 32 bold */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 43px;
  /* identical to box height */

  text-align: justify;

  /* Blue Soft */

  color: #7190ef;
}

/*=================================================================*/

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.file > input[type="file"] {
  display: none;
}

.file > label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.inner-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
  color: #a8adaf;
}

@keyframes pulse {
  0% {
    color: hsl(48, 100%, 67%);
  }

  50% {
    color: hsl(48, 100%, 38%);
  }

  100% {
    color: hsl(48, 100%, 67%);
  }
}
</style>
